import * as React from "react";
import {Component} from "react";
import {WindowParamContext} from "../../../library/context/WindowContext";
import {IDBPDatabase} from "idb";
import {deleteData, getData, initDatabase, MyData, updateData} from "../../../library/util/databaseUtil";
import {SizeAttr} from "../../../library/basic/size";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../library/basic/compose";
import {BoxUnit} from "../../../library/atomic/unit/BoxUnit";
import {ColorAttr} from "../../../library/basic/color";
import {LabCardData, LabPanelProps} from "./LabPanel";
import {CategoryButtonPcAndPad} from "./CategoryButtonPcAndPad";
import {SearchBox} from "./SearchBox";
import {DisplayMode, getDisplayMode, mediumPadding, smallPadding} from "../display/layout";
import {queryTcCvCharactersByPage, TcCvCharactersData} from "../data/TcCvCharacters";
import {queryTcCvTemplatesByPage, TcCvTemplatesData} from "../data/TcCvTemplates";
import {splitArrayIntoGroups} from "../../../library/util/arrayUtil";
import {TcCharacterCard, TcTemplateCard} from "../component/PagingCard";
import {TcCharacterDetailPanel, TcTemplateDetailPanel} from "../component/DetailPanelPc";
import {LoadingOutlined} from "@ant-design/icons";
import {PageSwitcherPc} from "../component/PageSwitcherPc";

export type LabPanelPcAndPadState = {
    currentCategory: string
    searchKeyword: string
    currentPageNum: number,
    currentTotal: number,
    dataList: LabCardData[]
    selectedId: string
}

export class LabPanelPcAndPad extends Component<LabPanelProps> {
    static contextType = WindowParamContext;

    labPanelStateDb: IDBPDatabase<MyData> | null = null

    stateSessionStorageKey(): string {
        return "LabPanelPcAndPad-Session-State" + "-" + this.props.panelName
    }

    stateStorageKey(): string {
        return "LabPanelPcAndPad-State" + "-" + this.props.panelName
    }

    state: LabPanelPcAndPadState = {
        currentCategory: "ALL",
        searchKeyword: "",
        currentPageNum: 1,
        currentTotal: -1,
        dataList: [],
        selectedId: ""
    }

    // 保存组件状态
    saveState(state: LabPanelPcAndPadState) {
        if (this.labPanelStateDb != null) {
            // console.log(this.stateStorageKey())
            updateData(this.labPanelStateDb, this.stateStorageKey(), 1,
                {id: 1, value: JSON.stringify(state)}).then(() => {
                // // console.log("LabPanelPcAndPadState save", this.state)
            })
        }
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            this.saveState(this.state)
            if (callback !== undefined) {
                callback()
            }
        })
    }

    // 从db中加载组件状态
    loadState(callback: (loadedState: LabPanelPcAndPadState | undefined) => void) {
        let getDataCall = () => {
            let loadedState = undefined
            // console.log("loadState before getData", this.labPanelStateDb)
            if (this.labPanelStateDb != null) {
                getData(this.labPanelStateDb, this.stateStorageKey(), 1).then((storageStateData) => {
                    // console.log("loadState after getData", storageStateData)
                    if (storageStateData != undefined) {
                        try {
                            loadedState = JSON.parse(storageStateData.value)
                            // console.log(loadedState)
                            callback(loadedState)
                        } catch (e) {
                            callback(undefined)
                        }
                    } else {
                        callback(undefined)
                    }
                }).catch(error => {
                    callback(undefined)
                })
            } else {
                callback(undefined)
            }
        }

        if (sessionStorage.getItem(this.stateSessionStorageKey()) == null) {
            sessionStorage.setItem(this.stateSessionStorageKey(), "init")
            // 从 indexDB 中清理状态数据
            if (this.labPanelStateDb != null) {
                let labPanelStateDb = this.labPanelStateDb
                getData(labPanelStateDb, this.stateStorageKey(), 1).then((storageStateData) => {
                    // console.log("loadState after getData", storageStateData)
                    if (storageStateData != undefined) {
                        deleteData(labPanelStateDb, this.stateStorageKey(), 1).then(() => {
                            getDataCall()
                        })
                    } else {
                        getDataCall()
                    }
                }).catch(error => {
                    getDataCall()
                })
            } else {
                getDataCall()
            }
        } else {
            getDataCall()
        }
    }

    componentDidMount() {
        // 初始化db，并加载状态
        initDatabase("LabPanelPcAndPad-" + this.props.panelName, this.stateStorageKey()).then((db) => {
            this.labPanelStateDb = db
            let loadedStateCallback: (loadedState: LabPanelPcAndPadState | undefined) => void = (loadedState) => {
                if (loadedState) {
                    this.setState(loadedState, () => {
                        if (loadedState.currentTotal < 0) {
                            this.triggerSearch()
                        }
                    })
                } else {
                    this.triggerSearch()
                }
            }
            // console.log("LabPanelPcAndPad db init success")
            this.loadState(loadedStateCallback)
            // console.log("LabPanelPcAndPad load stat over", this.state)
        })
    }

    triggerSearch() {
        this.setState(prevState => (Object.assign(prevState,
            {currentTotal: -1, selectedId: ""})), () => {
            if (this.props.panelName == "character") {
                queryTcCvCharactersByPage({
                    category: this.state.currentCategory,
                    keyword: this.state.searchKeyword,
                    page_num: this.state.currentPageNum,
                    page_size: 10
                }, (success, resp) => {
                    if (success && resp) {
                        this.updateAndSaveState({currentTotal: resp.data.total, dataList: resp.data.list})
                    } else {
                        this.updateAndSaveState({currentTotal: 0})
                    }
                })
            } else if (this.props.panelName == "template") {
                queryTcCvTemplatesByPage({
                    category: this.state.currentCategory,
                    keyword: this.state.searchKeyword,
                    page_num: this.state.currentPageNum,
                    page_size: 10
                }, (success, resp) => {
                    if (success && resp) {
                        this.updateAndSaveState({currentTotal: resp.data.total, dataList: resp.data.list})
                    } else {
                        this.updateAndSaveState({currentTotal: 0})
                    }
                })
            }
        })
    }

    // 根据当前选中的id，找到详情卡片的数据
    getDataFromSelectedId(): LabCardData | undefined {
        let res = undefined
        for (let i = 0; i < this.state.dataList.length; i++) {
            if (this.state.dataList[i].id == this.state.selectedId) {
                return this.state.dataList[i]
            }
        }
        return res
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    let {panelName, width, height, categoryList} = this.props

                    // 判断Pc or Pad布局
                    let panelMode: DisplayMode = getDisplayMode(wp.currWidth)

                    // 计算Pc布局，分页面板、详情面板的宽度
                    let detailPanelWidth = SizeAttr.pxMultiple(width, 594 / 1512)
                    let gapLineWidth = "2px"
                    let pagingPanelWidth = panelMode == "pc" ? (SizeAttr.getPxNumber(width) - 2 -
                        SizeAttr.getPxNumber(detailPanelWidth)) + "px" : width

                    // 计算分页面板内部布局
                    let pagingPanelInnerWidth = SizeAttr.pxMinus(pagingPanelWidth, "64px")

                    let searchBoxWidth = "201px"
                    let categoryButtonRowWidth = SizeAttr.pxMinus(pagingPanelInnerWidth, (201 + 32) + "px")

                    // 顶部标签和搜索元素高度
                    let categoryRowHeight = "32px"
                    let categoryRowTopMargin = "24px"
                    let categoryRowBottomMargin = "12px"
                    let categoryRowTotalHeight = (32 + 24 + 8) + "px"

                    // 底部分页切换元素高度
                    let pageSwitchRowHeight = "32px"
                    let pageSwitchRowTopMargin = "46px"
                    let pageSwitchRowBottomMargin = "60px"
                    let pageSwitchRowTotalHeight = (32 + 46 + 60) + "px"

                    // 滚动面板
                    let cardScrollingPanelHeight = SizeAttr.pxMinus(
                        SizeAttr.pxMinus(height, categoryRowTotalHeight), pageSwitchRowTotalHeight)
                    let cardWidth = SizeAttr.pxDivide(SizeAttr.pxMinus(pagingPanelInnerWidth,
                        SizeAttr.pxMultiple(mediumPadding, 4)), 5)

                    // 计算详情面板内部布局

                    return (
                        <RowUnit sizeAttr={new SizeAttr(wp, width, height)}>
                            <ColumnUnit sizeAttr={new SizeAttr(wp, pagingPanelWidth, height)}>
                                {/* 顶部标签和搜索 */}
                                <RowUnit
                                    customStyleAttr={{
                                        "marginTop": categoryRowTopMargin,
                                        "marginBottom": categoryRowBottomMargin
                                    }}
                                    sizeAttr={new SizeAttr(wp, pagingPanelWidth, categoryRowHeight)}
                                >
                                    <RowUnit
                                        classname={"lab-panel-container"}
                                        sizeAttr={new SizeAttr(wp, categoryButtonRowWidth, categoryRowHeight)}
                                        customStyleAttr={{
                                            "marginLeft": "32px",
                                            "marginRight": "auto",
                                            "overflowX": "scroll"
                                        }}
                                    >
                                        {
                                            this.props.categoryList.map((c) => {
                                                return <CategoryButtonPcAndPad
                                                    text={c}
                                                    selected={this.state.currentCategory == c}
                                                    buttonClick={() => {
                                                        this.updateAndSaveState({currentCategory: c, currentPageNum: 1}, () => {
                                                            this.triggerSearch()
                                                        })
                                                    }}
                                                />
                                            })
                                        }
                                    </RowUnit>
                                    <RowUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginRight": "32px",
                                        })}
                                    >
                                        {SearchBox(wp, searchBoxWidth, this.state.searchKeyword,
                                            (v) => {
                                                this.updateAndSaveState({searchKeyword: v})
                                            },
                                            () => {
                                                this.updateAndSaveState({currentPageNum: 1}, () => {
                                                    this.triggerSearch()
                                                })
                                            }
                                        )}
                                    </RowUnit>
                                </RowUnit>

                                {/* 滚动面板 */}
                                <ColumnUnit
                                    key={"LabelPanelPcAndPad-InnerScrollPagingCol-" +
                                        this.props.panelName + "-" + this.state.currentTotal}
                                    classname={"lab-panel-container"}
                                    sizeAttr={new SizeAttr(wp, pagingPanelWidth,
                                        this.state.currentTotal < 0 ?
                                            SizeAttr.pxPlus(cardScrollingPanelHeight, pageSwitchRowTotalHeight) : cardScrollingPanelHeight)}
                                    customStyleAttr={{
                                        "overflowY": "auto"
                                    }}
                                >
                                    {
                                        this.state.currentTotal < 0 &&
                                        <LoadingOutlined
                                            size={48}
                                            style={BoxAttr.getChildrenPositionObj()}/>
                                    }

                                    {
                                        this.state.currentTotal >= 0 &&
                                        splitArrayIntoGroups(this.state.dataList, 5).map((rowDataList, idx) => {
                                            return (
                                                <RowUnit
                                                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                        "marginTop": smallPadding,
                                                        "marginBottom": smallPadding
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, pagingPanelInnerWidth)}
                                                >
                                                    {
                                                        rowDataList.map((rd, rIdx) => {
                                                            if (!rd) {
                                                                return <div></div>
                                                            }

                                                            if (this.props.panelName == "character") {
                                                                return <TcCharacterCard
                                                                    wp={wp}
                                                                    width={cardWidth}
                                                                    fontSize={"18px"}
                                                                    marginRight={rIdx == 4 ? "" : mediumPadding}
                                                                    tcCvCharactersData={rd as TcCvCharactersData}
                                                                    selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                                    onClick={(d) => {
                                                                        this.updateAndSaveState({selectedId: d.id})
                                                                        this.props.updateDetailData(d)
                                                                    }}
                                                                    mouseEnterCallback={(d) => {
                                                                        this.updateAndSaveState({selectedId: d.id})
                                                                    }}
                                                                />
                                                            } else if (this.props.panelName == "template") {
                                                                return <TcTemplateCard
                                                                    wp={wp}
                                                                    width={cardWidth}
                                                                    fontSize={"18px"}
                                                                    marginRight={rIdx == 4 ? "" : mediumPadding}
                                                                    tcCvTemplatesData={rd as TcCvTemplatesData}
                                                                    selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                                    onClick={(d) => {
                                                                        this.updateAndSaveState({selectedId: d.id})
                                                                        this.props.updateDetailData(d)
                                                                    }}
                                                                />
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })
                                                    }
                                                </RowUnit>
                                            )
                                        })
                                    }
                                </ColumnUnit>

                                {/* 底部分页 */}
                                {
                                    this.state.currentTotal > 0 &&
                                    <ColumnUnit
                                        sizeAttr={new SizeAttr(wp, pagingPanelWidth)}
                                    >
                                        <ColumnUnit
                                            sizeAttr={new SizeAttr(wp, "", pageSwitchRowHeight)}
                                            customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                "marginTop": pageSwitchRowTopMargin
                                            })}>
                                            {PageSwitcherPc(wp, this.state.currentPageNum,
                                                Math.floor(this.state.currentTotal / 10) + 1, (v) => {
                                                    this.updateAndSaveState({currentPageNum: v}, () => {
                                                        this.triggerSearch()
                                                    })
                                                })}
                                        </ColumnUnit>
                                    </ColumnUnit>
                                }
                            </ColumnUnit>

                            {
                                panelMode == "pc" &&
                                <BoxUnit
                                    colorAttr={new ColorAttr(wp, "", "#232326")}
                                    sizeAttr={new SizeAttr(wp, gapLineWidth, height)}/>
                            }

                            {
                                panelMode == "pc" &&
                                <ColumnUnit sizeAttr={new SizeAttr(wp, detailPanelWidth, height)}>
                                    {
                                        this.props.panelName == "character" &&
                                        TcCharacterDetailPanel(wp, detailPanelWidth, height,
                                            this.getDataFromSelectedId() as (TcCvCharactersData | undefined),
                                            this.props.showCopyPanel)
                                    }
                                    {
                                        this.props.panelName == "template" &&
                                        TcTemplateDetailPanel(wp, detailPanelWidth, height,
                                            this.getDataFromSelectedId() as (TcCvTemplatesData | undefined),
                                            this.props.showCopyPanel)
                                    }
                                </ColumnUnit>
                            }
                        </RowUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}