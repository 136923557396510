import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {ColorAttr} from "../../../../library/basic/color";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {mediumRadius, popUpBaseUnit, popUpHeightNum, popUpWidthNum} from "../../display/layout";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import generationCloseIcon from "../../../../assets/prompt/copy/copy_panel_close_icon.svg";
import uploadCloudIcon from "../../../../assets/user/popup/generate/upload_cloud_icon.svg";
import characterPickIcon from "../../../../assets/user/popup/generate/character_pick_icon.svg";
import templatePickIcon from "../../../../assets/user/popup/generate/template_pick_icon.svg";
import trashIcon from "../../../../assets/user/popup/generate/trash_icon.svg";
import generateButton from "../../../../assets/user/popup/generate/generate_button.svg";
import {TcCvCharactersData} from "../../data/TcCvCharacters";
import {TcCvJobsData} from "../../data/TcCvJob";
import {TcCvTemplatesData} from "../../data/TcCvTemplates";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {PopupButton} from "./PopupButton";
import {CardSelectPopupProps} from "./CardSelectPopup";
import {tosLink} from "../../../../library/util/tosUtil";
import {GenerationPopupInitProps} from "../../TcCvMainPage";
import {addCvMixVideos} from "../../data/TcCvMixVideos";
import {LabIndex} from "../../TcCvPromptPage";
import {toLink} from "../../../../library/util/linkUtil";
import {useNavigate} from "react-router";

type CharacterGenData = {
    character: TcCvCharactersData | undefined
    job: TcCvJobsData | undefined
}

type TemplateGenData = {
    template: TcCvTemplatesData | undefined
    job: TcCvJobsData | undefined
}

interface GenerationPopupProps {
    wp: WindowParam
    setCardSelectPopupProps: (p: CardSelectPopupProps | undefined) => void
    setSelfCardSelectPopupProps: (p: CardSelectPopupProps | undefined) => void
    triggerUpload: (labIndex: LabIndex) => void
    closeClick: (triggerGenerate: boolean) => void
    registerCharacterConfirm: (dataConfirm: (character: TcCvCharactersData) => void) => void
    registerTemplateConfirm: (dataConfirm: (template: TcCvTemplatesData) => void) => void
    registerCharacterDataCallback: (dataCallback: (character: TcCvCharactersData) => void) => void
    registerTemplateDataCallback: (dataCallback: (template: TcCvTemplatesData) => void) => void
    initProps: GenerationPopupInitProps
}

export const GenerationPopup: FC<GenerationPopupProps> = ({
                                                              wp,
                                                              setCardSelectPopupProps,
                                                              setSelfCardSelectPopupProps,
                                                              triggerUpload,
                                                              closeClick,
                                                              registerCharacterConfirm,
                                                              registerTemplateConfirm,
                                                              registerCharacterDataCallback,
                                                              registerTemplateDataCallback,
                                                              initProps
                                                          }: GenerationPopupProps) => {
    const [characterGen, setCharacterGen] = useState<CharacterGenData>({
        character: initProps.character, job: undefined
    });
    const [templateGen, setTemplateGen] = useState<TemplateGenData>({
        template: initProps.template, job: undefined
    });
    const [inGeneration, setInGeneration] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        registerCharacterConfirm((d: TcCvCharactersData) => {
            setCharacterGen({
                character: d,
                job: undefined
            })
        })
        registerTemplateConfirm((d: TcCvTemplatesData) => {
            setTemplateGen({
                template: d,
                job: undefined
            })
        })
    }, []);

    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let buttonWidth = 252 * bUnit
    let buttonHeight = 42 * bUnit

    let innerPw = 1112 * bUnit
    let genBoxWidth = 540 * bUnit
    let genBoxHeight = 428 * bUnit

    let genInnerWidth = 506 * bUnit
    let genInnerHeight = 395 * bUnit
    let trashIconSize = 24 * bUnit
    let trashIconOffset = 15 * bUnit

    return (
        <BoxUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#161616")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick(false)
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": (19 * bUnit) + "px",
                    "right": (19 * bUnit) + "px"
                }}
                sizeAttr={new SizeAttr(wp, (21 * bUnit) + "px", (21 * bUnit) + "px")}
                url={generationCloseIcon}/>

            <ColumnUnit
                customStyleAttr={BoxAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerPw + "px")}>

                {/* 选择窗口 */}
                <RowUnit sizeAttr={new SizeAttr(wp, innerPw + "px")}>
                    {/* 角色窗口 */}
                    <ColumnUnit
                        customStyleAttr={{
                            "marginRight": "auto"
                        }}
                        sizeAttr={new SizeAttr(wp, genBoxWidth + "px")}>
                        <SpanText
                            sizeAttr={new SizeAttr(wp, "", (42 * bUnit) + "px")}
                            colorAttr={new ColorAttr(wp, "#a4a4a4")}
                            fontAttr={new FontAttr(wp, (28 * bUnit) + "px", "500")}
                        >
                            {"Character"}
                        </SpanText>
                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                setCardSelectPopupProps({
                                    wp: wp,
                                    labIndex: "character",
                                    selectSource: "recommend",
                                    dataConfirm: (d: any) => {
                                        setCharacterGen({
                                            character: d as TcCvCharactersData,
                                            job: undefined
                                        })
                                    },
                                    triggerUpload: triggerUpload,
                                    registerDataCallback: registerCharacterDataCallback,
                                    closeClick: () => {
                                        setCardSelectPopupProps(undefined)
                                    }
                                })
                            }}
                            customStyleAttr={{
                                "borderRadius": mediumRadius,
                                "border": "1.5px solid rgba(255,255,255,0.15)",
                                "marginBottom": (32 * bUnit) + "px",
                                "position": "relative"
                            }}
                            sizeAttr={new SizeAttr(wp, genBoxWidth + "px", genBoxHeight + "px")}>
                            {
                                characterGen.character == undefined && characterGen.job == undefined &&
                                <ColumnUnit
                                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                    sizeAttr={new SizeAttr(wp, "", (166 * bUnit) + "px")}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginBottom": "auto"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (101 * bUnit) + "px", (101 * bUnit) + "px")}
                                        url={characterPickIcon}/>
                                    <SpanText
                                        customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                                        colorAttr={new ColorAttr(wp, "#3F3E43")}
                                        fontAttr={new FontAttr(wp, (22 * bUnit) + "px", "500")}
                                    >
                                        {"select or upload a character"}
                                    </SpanText>
                                </ColumnUnit>
                            }
                            {
                                characterGen.character != undefined &&
                                <img
                                    onClick={(e) => {
                                        setCharacterGen({character: undefined, job: undefined})
                                        e.stopPropagation()
                                    }}
                                    style={{
                                        "position": "absolute",
                                        "top": trashIconOffset + "px",
                                        "right": trashIconOffset + "px",
                                        "width": trashIconSize + "px",
                                        "height": trashIconSize + "px"
                                    }}
                                    alt={"trash"}
                                    src={trashIcon}
                                />
                            }
                            {
                                characterGen.character != undefined &&
                                <ImageUnit
                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "maxWidth": genInnerWidth + "px",
                                        "maxHeight": genInnerHeight + "px"
                                    })}
                                    innerImgStyle={{
                                        "maxWidth": genInnerWidth + "px",
                                        "maxHeight": genInnerHeight + "px"
                                    }}
                                    url={tosLink(characterGen.character.cover)}/>
                            }
                        </BoxUnit>
                        <RowUnit
                            customStyleAttr={{
                                "marginBottom": (34 * bUnit) + "px"
                            }}
                            sizeAttr={new SizeAttr(wp, genBoxWidth + "px", buttonHeight + "px")}>
                            <PopupButton
                                wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                                fontSize={(22 * bUnit) + "px"}
                                text={"My Character"}
                                strokeColor={"linear-gradient(to right, #4DD1C180 0%, #4F78C880 26%, #8F51B880 51%, #BE578180 76%, #BF735380 100%)"}
                                fillColor={"linear-gradient(to right, #172422 0%, #171B23 26%, #1D1721 51%, #22181C 76%, #221A17 100%)"}
                                click={() => {
                                    setSelfCardSelectPopupProps({
                                        wp: wp,
                                        labIndex: "character",
                                        selectSource: "self",
                                        dataConfirm: (d: any) => {
                                            setCharacterGen({
                                                character: d as TcCvCharactersData,
                                                job: undefined
                                            })
                                        },
                                        registerDataCallback: registerCharacterDataCallback,
                                        triggerUpload: triggerUpload,
                                        closeClick: () => {
                                            setSelfCardSelectPopupProps(undefined)
                                        }
                                    })
                                }}
                                customStyleAttr={{"marginRight": "auto"}}
                            />
                            <PopupButton
                                wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                                fontSize={(22 * bUnit) + "px"}
                                text={"Upload"}
                                strokeColor={"#FAFAFA"}
                                fillColor={"#111111"}
                                icon={() => {
                                    return <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginLeft": (8 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                        url={uploadCloudIcon}/>
                                }}
                                click={() => {
                                    triggerUpload("character")
                                }}
                                customStyleAttr={{}}
                            />
                        </RowUnit>
                    </ColumnUnit>
                    {/* 模版窗口 */}
                    <ColumnUnit
                        sizeAttr={new SizeAttr(wp, genBoxWidth + "px")}>
                        <SpanText
                            sizeAttr={new SizeAttr(wp, "", (42 * bUnit) + "px")}
                            colorAttr={new ColorAttr(wp, "#a4a4a4")}
                            fontAttr={new FontAttr(wp, (28 * bUnit) + "px", "500")}
                        >
                            {"Template"}
                        </SpanText>
                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                setCardSelectPopupProps({
                                    wp: wp,
                                    labIndex: "template",
                                    selectSource: "recommend",
                                    dataConfirm: (d: any) => {
                                        setTemplateGen({
                                            template: d as TcCvTemplatesData,
                                            job: undefined
                                        })
                                    },
                                    registerDataCallback: registerTemplateDataCallback,
                                    triggerUpload: triggerUpload,
                                    closeClick: () => {
                                        setCardSelectPopupProps(undefined)
                                    }
                                })
                            }}
                            customStyleAttr={{
                                "borderRadius": mediumRadius,
                                "border": "1.5px solid rgba(255,255,255,0.15)",
                                "marginBottom": (32 * bUnit) + "px",
                                "position": "relative"
                            }}
                            sizeAttr={new SizeAttr(wp, genBoxWidth + "px", genBoxHeight + "px")}>
                            {
                                templateGen.template == undefined && characterGen.job == undefined &&
                                <ColumnUnit
                                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                    sizeAttr={new SizeAttr(wp, "", (166 * bUnit) + "px")}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginBottom": "auto"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (116 * bUnit) + "px", (116 * bUnit) + "px")}
                                        url={templatePickIcon}/>
                                    <SpanText
                                        customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                                        colorAttr={new ColorAttr(wp, "#3F3E43")}
                                        fontAttr={new FontAttr(wp, (22 * bUnit) + "px", "500")}
                                    >
                                        {"select or upload a animation"}
                                    </SpanText>
                                </ColumnUnit>
                            }
                            {
                                templateGen.template != undefined &&
                                <img
                                    onClick={(e) => {
                                        setTemplateGen({template: undefined, job: undefined})
                                        e.stopPropagation()
                                    }}
                                    style={{
                                        "position": "absolute",
                                        "top": trashIconOffset + "px",
                                        "right": trashIconOffset + "px",
                                        "width": trashIconSize + "px",
                                        "height": trashIconSize + "px"
                                    }}
                                    alt={"trash"}
                                    src={trashIcon}
                                />
                            }
                            {
                                templateGen.template != undefined &&
                                <ImageUnit
                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "maxWidth": genInnerWidth + "px",
                                        "maxHeight": genInnerHeight + "px"
                                    })}
                                    innerImgStyle={{
                                        "maxWidth": genInnerWidth + "px",
                                        "maxHeight": genInnerHeight + "px"
                                    }}
                                    url={tosLink(templateGen.template.cover)}/>
                            }
                        </BoxUnit>
                        <RowUnit
                            customStyleAttr={{
                                "marginBottom": (34 * bUnit) + "px"
                            }}
                            sizeAttr={new SizeAttr(wp, genBoxWidth + "px", buttonHeight + "px")}>
                            <PopupButton
                                wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                                fontSize={(22 * bUnit) + "px"}
                                text={"My Template"}
                                strokeColor={"linear-gradient(to right, #4DD1C180 0%, #4F78C880 26%, #8F51B880 51%, #BE578180 76%, #BF735380 100%)"}
                                fillColor={"linear-gradient(to right, #172422 0%, #171B23 26%, #1D1721 51%, #22181C 76%, #221A17 100%)"}
                                click={() => {
                                    setSelfCardSelectPopupProps({
                                        wp: wp,
                                        labIndex: "template",
                                        selectSource: "self",
                                        dataConfirm: (d: any) => {
                                            setTemplateGen({
                                                template: d as TcCvTemplatesData,
                                                job: undefined
                                            })
                                        },
                                        registerDataCallback: registerTemplateDataCallback,
                                        triggerUpload: triggerUpload,
                                        closeClick: () => {
                                            setSelfCardSelectPopupProps(undefined)
                                        }
                                    })
                                }}
                                customStyleAttr={{"marginRight": "auto"}}
                            />
                            <PopupButton
                                wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                                fontSize={(22 * bUnit) + "px"}
                                text={"Upload"}
                                strokeColor={"#FAFAFA"}
                                fillColor={"#111111"}
                                icon={() => {
                                    return <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginLeft": (8 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                        url={uploadCloudIcon}/>
                                }}
                                click={() => {
                                    triggerUpload("template")
                                }}
                                customStyleAttr={{}}
                            />
                        </RowUnit>
                    </ColumnUnit>
                </RowUnit>

                {/* 分割线 */}
                <BoxUnit
                    colorAttr={new ColorAttr(wp, "", "#434343")}
                    sizeAttr={new SizeAttr(wp, innerPw + "px", "2px")}/>

                {/* 选项栏 */}
                <RowUnit
                    customStyleAttr={{"marginTop": (32 * bUnit) + "px"}}
                    fontAttr={new FontAttr(wp, (24 * bUnit) + "px", "500")}
                    sizeAttr={new SizeAttr(wp, innerPw + "px")}>
                    <ColumnUnit customStyleAttr={{"marginRight": "auto"}}>
                        <RowUnit
                            customStyleAttr={{"marginBottom": (32 * bUnit) + "px"}}
                        >
                            <SpanText
                                colorAttr={new ColorAttr(wp, "#a1a1a1")}
                                sizeAttr={new SizeAttr(wp, (148 * bUnit) + "px")}
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "justifyContent": ""
                                })}>
                                {"Background"}
                            </SpanText>
                            <GenerationPopupSelect
                                wp={wp} value={"Original"} choiceList={[]}
                                setValue={(v) => {

                                }}
                                width={(150 * bUnit) + "px"} height={(40 * bUnit) + "px"}
                                radius={(10 * bUnit) + "px"}/>
                            <SpanText
                                colorAttr={new ColorAttr(wp, "#a1a1a1")}
                                sizeAttr={new SizeAttr(wp, (72 * bUnit) + "px")}
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "marginLeft": (32 * bUnit) + "px",
                                    "justifyContent": ""
                                })}>
                                {"Style"}
                            </SpanText>
                            <GenerationPopupSelect
                                wp={wp} value={"Classic"} choiceList={[]}
                                setValue={(v) => {

                                }}
                                width={(150 * bUnit) + "px"} height={(40 * bUnit) + "px"}
                                radius={(10 * bUnit) + "px"}/>
                        </RowUnit>
                        <RowUnit>
                            <SpanText
                                colorAttr={new ColorAttr(wp, "#a1a1a1")}
                                sizeAttr={new SizeAttr(wp, (148 * bUnit) + "px")}
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "justifyContent": ""
                                })}>
                                {"Watermark"}
                            </SpanText>
                            <GenerationPopupSelect
                                wp={wp} value={"ON"} choiceList={[]}
                                setValue={(v) => {

                                }}
                                width={(103 * bUnit) + "px"} height={(34 * bUnit) + "px"}
                                radius={(10 * bUnit) + "px"}/>
                        </RowUnit>
                    </ColumnUnit>

                    <ImageUnit
                        needHover={true}
                        customStyleAttr={{"marginTop": (20 * bUnit) + "px"}}
                        onClick={() => {
                            if (characterGen.character && templateGen.template && !inGeneration) {
                                // 避免重复生成
                                setInGeneration(true)
                                addCvMixVideos({
                                        character_id: characterGen.character.id,
                                        template_id: templateGen.template.id
                                    },
                                    (success, resp) => {
                                        if (success && resp) {
                                            // 触发生成任务成功
                                            closeClick(true)
                                            if (window.location.href.indexOf("mine") < 0) {
                                                toLink("/mine", navigate)
                                            }
                                        } else {
                                            setInGeneration(false)
                                        }
                                    })
                            }
                        }}
                        sizeAttr={new SizeAttr(wp, (218 * bUnit) + "px", (52 * bUnit) + "px")}
                        url={generateButton}/>
                </RowUnit>

            </ColumnUnit>

        </BoxUnit>
    )
}

interface GenerationPopupSelectProps {
    wp: WindowParam
    value: string,
    choiceList: string[],
    setValue: (v: string) => void,
    width: string,
    height: string,
    radius: string
}

export const GenerationPopupSelect: FC<GenerationPopupSelectProps> = ({
                                                                          wp,
                                                                          value,
                                                                          choiceList,
                                                                          setValue,
                                                                          width,
                                                                          height,
                                                                          radius
                                                                      }: GenerationPopupSelectProps) => {
    return (
        <RowUnit
            colorAttr={new ColorAttr(wp, "#fafafa", "#242424")}
            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                "borderRadius": radius,
            })}
            sizeAttr={new SizeAttr(wp, width, height)}
        >
            <SpanText customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                {value}
            </SpanText>
        </RowUnit>
    )
}